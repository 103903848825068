<template>
  <section class="hero gradient">
    <div class="container">
      <div class="hero-body">
        <h2 class="title">
          With your help, here are just some of the Good Causes we’ve supported in {{ vm.activeCounty }}!
        </h2>
        <div class="stories-container columns">

            <div @click="goTo(`/${$route.params.year}/article/${story.county}/${story.slug}`)" class="column story" v-for="(story, idx) in vm.articles" v-bind:key="idx">
              <div class="story-pill">{{ story.county }}</div>
              <div class="story-image">
                <img :src="`${cdnUrl}/photos/640/${story.image}`" />
              </div>
              <div class="story-footer">
                <h3>{{ story.title }}</h3>
                <div class="story-body" v-html="story.excerpt"></div>
              </div>
            </div>

        </div> 
      </div> 
    </div> 
  </section>
</template>

<script>
import { observer } from "mobx-vue" 
import { store } from "@/services/store"
import CFG from '@/services/config';

export default observer({
  name: 'LocalStoriesPanel',
  props: {
    gradient: Boolean,
    //stories: Array,
    //county: String,
  },
  data: () => ({
    cdnUrl: CFG.cdnUrl,
    vm: store
  }),
  methods: {
    goTo(routeName) {
      this.$router.push(routeName);
    },
  }
})
</script>

<style lang="scss" scoped>
section {
  margin-top: 3em;
}
.container {
  margin: 0 auto;
  width: 100%;
}
.hero-body {
  text-align: center;
  overflow: hidden;
}
h2 {
  color: #FFF;
  font-weight: 900;
  font-size: 30px;
  line-height: 32px;
  width: 100%;
  margin: 1em auto 1em auto; 
}
.gradient { 
  background: linear-gradient(90deg, #1C4240 -46.67%, #3D7A3B 10.14%, #4D9945 46.86%, #BDD630 101.55%);
  opacity: 0.9;
}
.stories-container {
  padding: 0;
  color: #333;
}
.story {
  width: 360px;
  height: 400px;
  margin: 0 auto;
  position: relative;
}
.story-image {
  height: 200px;
  overflow: hidden;
  border-radius: 1em 1em 0 0;
}
.story-footer {
  height: 180px;
  padding: 1em;
  background-color: #FFF;
  text-align: left;
  border-radius: 0 0 1em 1em;
  .story-body{
    overflow: hidden;
    max-height: 120px;
  }
  h3 {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }
}
.story-pill {
  color: #FFF;
  position: absolute;
  top: 0.75rem;
  right: 12px;
  border-radius: 0 1em 0 0.5em;
  padding: 0.5em 2em;
  background-color: #C4DC33;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
}
@media only screen and (min-width: 768px) {
  .story {
   margin: 1em;
  }
  .story-pill {
    top: 1em;
    right:0;
    border-radius: 0.5em 0 0 0.5em;
  }
}
@media only screen and (max-width: 1023px) {
  .hero .container {
    max-width: 100%;
  }
}
</style>

