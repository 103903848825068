<template>
  <section>
    <LocalStoriesPanel :county="vm.activeCounty" :stories="vm.articles" />
    <HowToApplyPanel :gradient="true" />
  </section>
</template>

<script>
import { observer } from "mobx-vue" 
import { store } from "@/services/store"
import HowToApplyPanel from '@/components/HowToApplyPanel.vue'
import LocalStoriesPanel from '@/components/LocalStoriesPanel.vue'
import { counties } from '@/services/data';

export default observer({
  name: 'County',
  components: {
    HowToApplyPanel,
    LocalStoriesPanel
  },
  data: () => ({
    vm: store,
    counties
  }),
  created() {
    this.vm.activeArticle = false;
    const county = this.$route.params.county;
    if (this.counties.includes(county)){
      this.vm.fetchArticles(county)
    }
  },
  beforeRouteLeave(to, from, next) {
      if('Home' === to.name){
        this.vm.activeCounty = 'Ireland'
        this.vm.fetchHome()
      }
      return next()
  },
  mounted(){

  }
})
</script>


