<template>
<section class="section">
    <ArticleBreadcrumbs :county="$route.params.county"/>
  <div class="container">
    <ArticleMain :article="vm.article" :activeArticle="vm.activeArticle" />
    <StoriesPanel :gradient="false" :stories="vm.articles" />
  </div>
</section>
</template>

<script>
import { observer } from "mobx-vue" 
import { store } from "@/services/store"
import ArticleMain from '@/components/ArticleMain.vue'
import ArticleBreadcrumbs from '@/components/ArticleBreadcrumbs.vue'
import StoriesPanel from '@/components/StoriesPanel.vue'
import { counties } from '@/services/data';

export default observer({
  name: 'Article',
  components: {
    ArticleBreadcrumbs,
    ArticleMain,
    StoriesPanel
  },
  data: () => ({
    vm: store,
    article: null,
    featured: null,
    counties
  }),
  head: {
    title: function () {
      return {
        inner: this.vm.metaTitle
      }
    },
    meta: function () {
      return [
      { id: 'twitterTitle', name: 'twitter:title', content: this.vm.metaTitle },
      { id: 'twitterDescription', name: 'twitter:description', content: this.vm.metaDescription },
      { id: 'ogTitle', property: 'og:title', content: this.vm.metaTitle }
      ]
    }
  },
  mounted() {
    const county = this.$route.params.county;
    const slug = this.$route.params.slug;
    if (this.counties.includes(county)){
      this.vm.fetchArticle(county, slug)
    }
  },
  beforeRouteLeave(to, from, next) {
     console.log(to.params.county);
      if('Home' === to.name){
        this.vm.activeCounty = 'Ireland'
        this.vm.fetchHome()
      } else {
        this.vm.activeCounty = to.params.county;
      }
      return next()
  },
  methods: {

  }
})
</script>

<style lang="scss" scoped>
  section.section {
    margin: 0;
    padding: 0;
    padding-top: 0.75em;
  }

</style> 