<template>
  <section class="section">
    <div class="gradient2"></div>
    <div class="gradient1"></div>
    <div class="container">
      <ul>
        <li @click="goTo(`/${$route.params.year}`)">Good Causes</li>
        <li @click="goTo(`/${$route.params.year}/county/${county}`)">{{ county }} View</li>
        <li class="pill">Story</li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ArticleBreadcrumbs',
  props: {
    county: String
  },
  methods: {
    goTo(routeName) {
      this.$router.push(routeName);
    }
  }
}
</script>

<style lang="scss" scoped>
.section{
  padding: 1em;
  position: relative;
  ul {
    display: inline-flex;
    li {
      cursor: pointer;
      margin-right: 1em;
      padding: 0.1em 1em;
      color: #FFF;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.8rem;
      line-height:1.2em;
      &.pill{
        cursor: default;
        color: #333;
        background-color: #D7E67F;
        border-radius: 1em;
      }
    }
  }
  .gradient1{
    position: absolute;
    left:0;
    right:0;
    top:0;
    height: 120px;
    background: linear-gradient(102.3deg, #1C4240 -46.67%, #3D7A3B 10.14%, #4D9945 46.86%, #BDD630 101.55%);
    mix-blend-mode: multiply;
    opacity: 0.5;
    clip-path:  ellipse(120% 50% at 0% 0%);

  }
  .gradient2{
    position: absolute;
    left:0;
    right:0;
    top:0;
    height: 180px;
    //background: linear-gradient(102.3deg, #1C4240 -46.67%, #3D7A3B 10.14%, #4D9945 46.86%, #BDD630 101.55%);
    background: linear-gradient(102.3deg, #1C4240 -46.67%, #3D7A3B 10.14%, #4D9945 46.86%, #BDD630 101.55%);
    opacity: 1;
    clip-path:  ellipse(120% 50% at 100% 0%);
    //transform: matrix(0.87, 0.48, -0.49, 0.88, 0, 0);
  }
}
@media only screen and (min-width: 480px) {
  .section ul li {
    font-size: 0.9em;
    line-height:1.1em;
  }
}
@media only screen and (min-width: 768px) {
  .section ul li {
    font-size: 1em;
    line-height:1.2em;
  }
}
</style>
