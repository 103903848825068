<template>
  <section class="section">
    <h1 v-if="activeArticle">{{ article.title }}</h1>
    <div class="article-image">
      <img v-if="activeArticle" :src="`${cdnUrl}/photos/1152/${article.image}`"  :alt="article.image_caption" />
    </div>
    <div class="article-meta" v-if="activeArticle">
      <p class="meta-description">{{ article.image_caption }}</p>
      <p class="meta-date">Posted {{ article.date }}</p>
      <ul class="social-links">
        <li>
          <ShareNetwork
              network="facebook"
              :url="`${baseUrl}/article/${article.county}/${article.slug}`"
              :title="`${article.title} - A @NationalLottery Good Cause.`"
              :description="article.excerpt"
              :media="`${cdnUrl}/photos/960/${article.image}`"
              quote=""
              hashtags=""
            >
              <img src="@/assets/icon_fb.png" />
          </ShareNetwork>
        </li>
        <li>
          <ShareNetwork
              network="linkedin"
              :url="`${baseUrl}/article/${article.county}/${article.slug}`"
              :title="`${article.title} - A @NationalLottery Good Cause.`"
              :description="article.excerpt"
              :media="`${cdnUrl}/photos/960/${article.image}`"
              quote=""
              hashtags=""
            >
              <img src="@/assets/icon_linkedin.png" />
          </ShareNetwork>
        </li>
        <li>
          <ShareNetwork
              network="twitter"
              :url="`${baseUrl}/article/${article.county}/${article.slug}`"
              :title="`${article.title} - A @NationalLottery Good Cause.`"
              :description="article.excerpt"
              :media="`${cdnUrl}/photos/960/${article.image}`"
              quote=""
              hashtags=""
            >
              <img src="@/assets/icon_twitter.png" />
          </ShareNetwork>
        </li>
      </ul>
    </div>
    <article v-if="article" v-html="article.body">

    </article>
  </section>
</template>

<script>
import {scroller} from 'vue-scrollto/src/scrollTo'
import CFG from '@/services/config';

export default {
  name: 'Article',
  props: {
    activeArticle: Boolean,
    article: Object,
  },
  data: () => ({
    baseUrl: CFG.baseUrl,
    cdnUrl: CFG.cdnUrl
  }),
  mounted() {
    this.scrollTop()
  },
  methods: {
    scrollTop(){
      const scrollMain = scroller()
      scrollMain('#app')
    }
  }
}
</script>

<style lang="scss" scoped>

  h1 {
    font-weight: 900;
    font-size: 2.8em;
    width: 90%;
    margin: 1em 0 0.5em 0;
  }
  .article-image {
    //min-height: 600px;
    img {
      //max-height: 50vh;
      width: 100%;
      height: 66.666%;
    }
  }
  .article-meta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0;
  }

  .meta-date {
    font-weight: 700;
  }
  .social-links {
    display: flex;
    li {
      width: 23px;
      margin-right: 1.5em;
    }
  }
  article{
    margin-top: 1em;
    margin-bottom: 3em;
    font-size: 1.2em;
    line-height: 1.4em;
  }
  .meta-description, .meta-date, .social-links {
    width: 100%;
    padding: 0.5em 0;
  }


@media only screen and (min-width: 768px) {
  h1 {
    font-weight: 900;
    font-size: 50px;
    line-height: 54px;
    width: 80%;
    margin: 1em 0;
  }
  .article-meta {
    flex-direction: row;
  }
  .social-links li {
    margin-left: 1.5em;
  }
  .meta-description, .meta-date, .social-links {
    width: auto;
    padding: 0;
  }
  article{
    margin-top: 3em;
    font-size: 1.4em;
    line-height: 1.6em;
  }
}
@media only screen and (max-width: 1009px) {
  h1, .article-meta, article {
    padding: 0 1.5em;
  }
}
</style>
